import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { uiActions } from '../store/reducers/ui-slice';
import { snackbarType } from '../components/SnackBarAlert';

const useSnackbar = () => {
  const dispatch = useDispatch();

  const showSuccessSnackbar = useCallback(
    (message) => {
      dispatch(
        uiActions.setSnackbar({ message: message, type: snackbarType.SUCCESS }),
      );
    },
    [dispatch],
  );

  const showErrorSnackbar = useCallback(
    (message) => {
      dispatch(
        uiActions.setSnackbar({ message: message, type: snackbarType.ERROR }),
      );
    },
    [dispatch],
  );

  const showInfoSnackbar = useCallback(
    (message) => {
      dispatch(
        uiActions.setSnackbar({ message: message, type: snackbarType.INFO }),
      );
    },
    [dispatch],
  );

  const showWarningSnackbar = useCallback(
    (message) => {
      dispatch(
        uiActions.setSnackbar({ message: message, type: snackbarType.WARNING }),
      );
    },
    [dispatch],
  );

  const closeSnackbar = useCallback(() => {
    dispatch(
      uiActions.setSnackbar({ message: null, type: snackbarType.ERROR }),
    );
  }, [dispatch]);

  return {
    showSuccessSnackbar,
    showErrorSnackbar,
    showInfoSnackbar,
    showWarningSnackbar,
    closeSnackbar,
  };
};

export default useSnackbar;
