import React from 'react';
import {
  useTheme,
  Box,
  makeStyles,
  Divider,
  colors,
  useMediaQuery,
} from '@material-ui/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CachedIcon from '@material-ui/icons/Cached';

const BookingItem = ({
  ref_id = '',
  selling_credits = '',
  purchase_date = '',
  validity_upto = '',
  purchase_status = '',
  package_name = '',
  user_name = '',
  divider = true,
  loading = false,
}) => {
  dayjs.extend(utc);

  const classes = useStyles();
  const theme = useTheme();

  const matchesUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchesUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const patientName = (
    <Grid
      item
      container
      justify="center"
      alignItems="center"
      xs={12}
      md={3}
      spacing={matchesUpMd ? 1 : 0}
      direction={matchesUpMd ? 'column' : 'row'}
      style={{
        lineHeight: matchesUpMd ? 1.5 : 1,
        margin: matchesUpMd ? 0 : `${theme.typography.pxToRem(6)} 0`,
      }}
    >
      {loading ? (
        <Skeleton width={theme.typography.pxToRem(200)} />
      ) : (
        <>
          <Grid
            item
            className={[classes.SmallHeadingText, classes.FontSizeUpSm].join(
              ' ',
            )}
          >
            {matchesUpSm ? 'Patient Name:' : 'Patient:'}
          </Grid>{' '}
          <Grid
            item
            style={
              matchesUpMd
                ? { textAlign: 'center' }
                : { marginLeft: theme.typography.pxToRem(10) }
            }
            className={
              matchesUpMd
                ? [classes.Truncate, classes.FontSizeUpSm].join(' ')
                : ''
            }
          >
            {user_name}
          </Grid>
        </>
      )}
    </Grid>
  );

  const puchasedDate = (
    <Grid
      item
      container
      direction="column"
      alignItems="center"
      xs={5}
      md={2}
      spacing={matchesUpMd ? 1 : 0}
    >
      <Grid
        item
        className={[classes.SmallHeadingText, classes.FontSizeUpSm].join(' ')}
      >
        {loading ? (
          <Skeleton width={theme.typography.pxToRem(100)} />
        ) : (
          'Purchase Date:'
        )}
      </Grid>
      <Grid
        item
        className={[classes.SmallText, classes.FontSizeUpSm].join(' ')}
      >
        {loading ? (
          <Skeleton width={theme.typography.pxToRem(80)} />
        ) : dayjs.utc(purchase_date).isUTC() ? (
          dayjs
            .utc(purchase_date)
            .format(
              matchesUpMd && !matchesUpLg ? 'MMM DD, YYYY' : 'MMMM DD, YYYY',
            )
        ) : (
          ''
        )}
      </Grid>
    </Grid>
  );

  const validUpto = (
    <Grid
      item
      container
      direction="column"
      alignItems="center"
      xs={5}
      md={2}
      spacing={matchesUpMd ? 1 : 0}
    >
      <Grid
        item
        className={[classes.SmallHeadingText, classes.FontSizeUpSm].join(' ')}
      >
        {loading ? (
          <Skeleton width={theme.typography.pxToRem(100)} />
        ) : (
          'Valid Upto:'
        )}
      </Grid>
      <Grid
        item
        className={[classes.SmallText, classes.FontSizeUpSm].join(' ')}
      >
        {loading ? (
          <Skeleton width={theme.typography.pxToRem(80)} />
        ) : dayjs.utc(validity_upto).isUTC() ? (
          dayjs
            .utc(validity_upto)
            .format(
              matchesUpMd && !matchesUpLg ? 'MMM DD, YYYY' : 'MMMM DD, YYYY',
            )
        ) : (
          ''
        )}
      </Grid>
    </Grid>
  );

  return (
    <Box
      style={
        loading && matchesUpMd && divider
          ? { marginBottom: theme.typography.pxToRem(8) }
          : {}
      }
    >
      {loading && matchesUpMd ? (
        <Skeleton
          variant="rect"
          width="100%"
          height={theme.typography.pxToRem(120)}
        />
      ) : (
        <>
          <Grid
            container
            alignItems="center"
            spacing={1}
            className={classes.ItemContainer}
          >
            <Grid item>
              {loading ? (
                <Skeleton
                  variant="text"
                  width={theme.typography.pxToRem(100)}
                />
              ) : (
                <div className={classes.SmallHeadingText}>
                  Ref ID:{' '}
                  <span
                    style={{
                      marginLeft: theme.typography.pxToRem(10),
                      color: '#000',
                    }}
                  >
                    {ref_id}
                  </span>
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item container xs={7} md={3} direction="column">
                  <Grid item style={{ width: '100%' }}>
                    <h6
                      style={{ width: matchesUpMd ? '90%' : '95%' }}
                      className={[classes.Heading, classes.Truncate].join(' ')}
                    >
                      {loading ? (
                        <Skeleton width={theme.typography.pxToRem(150)} />
                      ) : (
                        package_name
                      )}
                    </h6>
                  </Grid>
                  <Grid item>
                    {loading ? (
                      <Skeleton
                        variant="text"
                        width={theme.typography.pxToRem(100)}
                      />
                    ) : (
                      <>
                        <span
                          className={[
                            classes.SmallHeadingText,
                            classes.FontSizeUpSm,
                          ].join(' ')}
                        >
                          Credits Earned:
                        </span>{' '}
                        <span
                          className={[
                            classes.SmallText,
                            classes.FontSizeUpSm,
                          ].join(' ')}
                        >
                          {selling_credits}
                        </span>
                      </>
                    )}
                  </Grid>
                </Grid>
                {matchesUpMd && (
                  <>
                    {puchasedDate}
                    {validUpto}
                    {patientName}
                  </>
                )}
                <Grid
                  item
                  xs={5}
                  md={2}
                  className={[
                    classes.SmallHeadingText,
                    classes.FontSizeUpSm,
                  ].join(' ')}
                  style={{ lineHeight: 1, fontWeight: matchesUpMd ? 400 : 700 }}
                >
                  {loading ? (
                    <h1 style={{ display: 'flex', justifyContent: 'center' }}>
                      <Skeleton width={theme.typography.pxToRem(75)} />
                    </h1>
                  ) : purchase_status === '1' ? (
                    <Grid
                      container
                      alignItems="center"
                      justify="center"
                      style={{ color: theme.palette.error.main }}
                    >
                      <span
                        style={{ marginRight: theme.typography.pxToRem(4) }}
                      >
                        <ErrorOutlineIcon color="inherit" />
                      </span>
                      <span>Pending</span>
                    </Grid>
                  ) : purchase_status === '2' ? (
                    <Grid
                      container
                      alignItems="center"
                      justify="center"
                      style={{ color: theme.palette.secondary.main }}
                    >
                      <span
                        style={{ marginRight: theme.typography.pxToRem(4) }}
                      >
                        <CachedIcon color="inherit" />
                      </span>
                      <span>In-progress</span>
                    </Grid>
                  ) : purchase_status === '3' ? (
                    <Grid
                      container
                      alignItems="center"
                      justify="center"
                      style={{ color: theme.palette.success.main }}
                    >
                      <span
                        style={{ marginRight: theme.typography.pxToRem(4) }}
                      >
                        <CheckCircleOutlineIcon color="inherit" />
                      </span>
                      <span>Done</span>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>

            {!matchesUpMd ? patientName : null}

            {!matchesUpMd && (
              <Grid item container justify="space-around">
                {puchasedDate}
                <Divider orientation="vertical" flexItem />
                {validUpto}
              </Grid>
            )}
          </Grid>
          {divider && <Divider />}
        </>
      )}
    </Box>
  );
};

export default BookingItem;

const useStyles = makeStyles((theme) => ({
  ItemContainer: {
    margin: theme.spacing(theme.typography.pxToRem(16), 0),
  },
  SmallHeadingText: {
    fontSize: theme.typography.pxToRem(12),
    color: colors.grey[500],
  },
  SmallText: {
    fontSize: theme.typography.pxToRem(12),
  },
  FontSizeUpSm: {
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  Truncate: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  Heading: {
    margin: 0,
    fontSize: theme.typography.pxToRem(18),

    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
    },
  },
}));
