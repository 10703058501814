import React, { useState, useLayoutEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export const snackbarType = {
  ERROR: 'error',
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
};

const SnackBarAlert = ({
  message = null,
  type = snackbarType.ERROR,
  duration = 2000,
  closeSnackBar = () => {},
}) => {
  const [severityType, setSeverityType] = useState(type);

  useLayoutEffect(() => {
    if (message) {
      setSeverityType(type);
    }
  }, [message, type]);

  return (
    <Snackbar
      onExited={setSeverityType.bind(this, snackbarType.ERROR)}
      open={!!message}
      autoHideDuration={duration}
      onClose={closeSnackBar}
    >
      <Alert onClose={closeSnackBar} severity={severityType}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackBarAlert;
