import React from 'react';
import { InputAdornment, TextField as MuiTextField } from '@material-ui/core';
import { makeStyles, fade } from '@material-ui/core/styles';

import { ShowCustomizedEndAdornment } from '../Input';

const RawTextField = React.forwardRef(
  (
    {
      type = 'text',
      error = false,
      touched = false,
      variant = 'outlined',
      showValidationDefault = true,
      renderEndAdornment = ({ errorAndSuccessComponents }) => (
        <InputAdornment position="end">
          {errorAndSuccessComponents}
        </InputAdornment>
      ),
      ...props
    },
    ref,
  ) => {
    const {
      classes: { root: textFieldRootClasses = '' } = { root: '' },
      InputProps: {
        classes: {
          root: InputPropsRootClasses = '',
          notchedOutline: notchedOutlineClasses = '',
          focused: focusedClasses = '',
          error: errorInputClasses = '',
          input: inputClasses = '',
        } = { root: '', notchedOutline: '', focused: '', error: '', input: '' },
      } = {
        classes: {
          root: '',
          notchedOutline: '',
          focused: '',
          error: '',
          input: '',
        },
      },
      InputProps: { classes: InputPropsClasses = {} } = { classes: {} },
      InputProps: InputMultiProps = {},
    } = props;

    const classes = useStyles();

    const rootClassNames = [classes.InputOutlinedRoot];
    const focusedClassNames = [classes.InputOutlinedRootFocused];

    if (showValidationDefault) {
      if (error) {
        focusedClassNames.push(classes.InputOutlinedRootErrorFocused);
      } else if (touched) {
        rootClassNames.push(classes.InputOutlinedRootSuccess);
        focusedClassNames.push(classes.InputOutlinedRootSuccessFocused);
      }
    }

    let OutlinedProps = {};
    if (variant === 'outlined') {
      OutlinedProps = {
        InputProps: {
          ...InputMultiProps,
          error: error,
          classes: {
            ...InputPropsClasses,
            root: [...rootClassNames, InputPropsRootClasses].join(' '),
            notchedOutline: [
              classes.InputNotchedOutline,
              notchedOutlineClasses,
            ].join(' '),
            focused: [...focusedClassNames, focusedClasses].join(' '),
            error: showValidationDefault
              ? [classes.InputOutlinedRootError, errorInputClasses].join(' ')
              : errorInputClasses,
            input: [
              classes.Input,
              InputMultiProps.startAdornment
                ? classes.InputWithStartAdornment
                : '',
              inputClasses,
            ].join(' '),
          },
          endAdornment: (
            <ShowCustomizedEndAdornment
              error={error}
              touched={touched}
              render={renderEndAdornment}
            />
          ),
        },
      };
    }

    return (
      <MuiTextField
        inputMode={type}
        type={type}
        inputRef={ref}
        variant={variant}
        {...props}
        classes={{
          root: [classes.TextFieldRoot, textFieldRootClasses].join(' '),
        }}
        {...OutlinedProps}
      />
    );
  },
);

export default RawTextField;

const useStyles = makeStyles((theme) => ({
  TextFieldRoot: {
    display: 'flex',
  },
  InputOutlinedRoot: {
    width: '100%',
    height: '50px',
    background: '#f0f0f0',
    color: '#000',
    borderRadius: '10px',
    transition: 'all 0.3s',

    '& > div': {
      padding: '0 0.1rem',
    },

    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.secondary.main,
    },

    '&:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
  },
  InputNotchedOutline: {
    boxShadow: '0 0 0 4px transparent',
    borderColor: 'transparent',
    transition: 'border-color 0.3s, box-shadow 0.5s',
    borderRadius: '10px',
  },
  InputOutlinedRootFocused: {
    background: '#fefefe',

    '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.secondary.main}`,
      boxShadow: `0 0 0 4px ${fade(theme.palette.secondary.main, 0.3)}`,
    },
  },
  InputOutlinedRootError: {
    background: fade(theme.palette.error.main, 0.2),
  },
  InputOutlinedRootErrorFocused: {
    background: '#fefefe',

    '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.error.main}`,
      boxShadow: `0 0 0 4px ${fade(theme.palette.error.main, 0.3)}`,
    },
  },
  InputOutlinedRootSuccess: {
    background: fade(theme.palette.success.main, 0.2),
  },
  InputOutlinedRootSuccessFocused: {
    background: '#fefefe',

    '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.success.main}`,
      boxShadow: `0 0 0 4px ${fade(theme.palette.success.main, 0.3)}`,
    },
  },
  Input: {
    width: '100%',
    height: '100%',
    padding: '0px 20px',
  },
  InputWithStartAdornment: {
    padding: '0 20px 0 0',
  },
}));
