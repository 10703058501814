import React, { useEffect } from 'react';
import { Grid, Box, useTheme, makeStyles } from '@material-ui/core';
import { SyncLoader } from 'react-spinners';

import Page from '../hoc/Page';
import PageHeading from '../components/PageHeading';
import PrimaryCard from '../components/Card/PrimaryCard';
import useApiCall from '../hooks/useApiCall';
import URIS from '../api';
import BookingItem from '../containers/BookingItem';

const Booking = () => {
  const classes = useStyles();
  const theme = useTheme();

  const { myFetch, responseData, setResponseData, isLoading } = useApiCall();

  useEffect(() => {
    myFetch({
      url: URIS.LIST_HOSPITAL_BOOKINGS,
      payload: {
        pattern: '',
        table_config_rows_per_page: '10',
        table_config_page_no: '1',
      },
    }).catch((error) => {
      setResponseData({
        response: {
          response_bookings: [],
          total_sell: '0',
          pending_bookings: '0',
        },
      });
    });
  }, [myFetch, setResponseData]);

  return (
    <Page>
      <Box className={classes.PageContent}>
        <Grid container>
          <Grid
            item
            container
            xs={12}
            md={10}
            lg={12}
            spacing={3}
            style={{
              margin: theme.spacing(
                0,
                'auto',
                theme.typography.pxToRem(20),
                'auto',
              ),
            }}
          >
            <Grid item xs={12} lg={4}>
              <PageHeading
                autoResponsive={false}
                classes={{ head: classes.PageHeading }}
              >
                All
                <br />
                <strong>Bookings</strong>
              </PageHeading>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Box className={classes.CardsContainer}>
                <PrimaryCard
                  cardColor="#4A899C"
                  value={
                    isLoading ? (
                      <SyncLoader color={theme.palette.primary.contrastText} />
                    ) : (
                      responseData?.response?.total_sell || '0'
                    )
                  }
                  title="Total Sold"
                  autoResponsive={false}
                  classes={{
                    cardValue: classes.CardValue,
                    cardTitle: classes.CardTitle,
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Box className={classes.CardsContainer}>
                <PrimaryCard
                  cardColor="#472C94"
                  value={
                    isLoading ? (
                      <SyncLoader color={theme.palette.primary.contrastText} />
                    ) : (
                      responseData?.response?.pending_bookings || '0'
                    )
                  }
                  title="Bookings Pending"
                  autoResponsive={false}
                  classes={{
                    cardValue: classes.CardValue,
                    cardTitle: classes.CardTitle,
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {Array.isArray(responseData?.response?.response_bookings)
              ? responseData.response.response_bookings.map((item, index) => (
                  <BookingItem
                    key={item?.purchase_id}
                    {...item}
                    divider={
                      responseData.response.response_bookings.length - 1 !==
                      index
                    }
                  />
                ))
              : []}
            {!isLoading &&
            (Array.isArray(responseData?.response?.response_bookings)
              ? responseData.response.response_bookings
              : []
            ).length <= 0 ? (
              <Box
                height={theme.typography.pxToRem(124)}
                width="100%"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: theme.typography.pxToRem(16),
                }}
              >
                No Records Found!
              </Box>
            ) : null}
            {isLoading &&
              Array.from({ length: 10 }, (_, index) => (
                <BookingItem key={index} loading divider={index !== 9} />
              ))}
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default Booking;

const useStyles = makeStyles((theme) => ({
  PageContent: {
    width: '90%',
    maxWidth: theme.typography.pxToRem(1400),
    margin: theme.spacing(
      theme.typography.pxToRem(120),
      'auto',
      theme.typography.pxToRem(50),
      'auto',
    ),
  },
  PageHeading: {
    fontSize: theme.typography.pxToRem(40),

    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(65),
    },
  },
  CardsContainer: {
    height: theme.typography.pxToRem(190),

    [theme.breakpoints.up('sm')]: {
      height: theme.typography.pxToRem(200),
    },

    [theme.breakpoints.up('md')]: {
      height: theme.typography.pxToRem(280),
    },

    [theme.breakpoints.up('lg')]: {
      height: theme.typography.pxToRem(270),
    },
  },
  CardValue: {
    fontSize: theme.typography.pxToRem(45),

    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(40),
    },

    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(60),
    },
  },
  CardTitle: {
    fontSize: theme.typography.pxToRem(22),

    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(20),
    },

    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(28),
    },

    [theme.breakpoints.up('lg')]: {
      fontSize: theme.typography.pxToRem(30),
    },
  },
  table: {
    minWidth: '100%',
    boxShadow: 'none',
  },
}));
