import React, { useEffect } from 'react';
import { createMuiTheme, ThemeProvider, CssBaseline } from '@material-ui/core';
import { Route, useLocation, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Booking from './pages/Booking';
import Profile from './pages/Profile';
import Login from './pages/Login';
import Header from './containers/Header';
import Loader from './components/Loader';
import TransitionSwitch from './hoc/TransitionSwitch';
import routeDefinitions, { ROUTES } from './routes';
import { loginUsingSavedCredentials } from './store/thunks/auth-thunks';
import SnackBarHOC from './hoc/SnackBarHOC';
import HandleError from './hoc/HandleError';
import Initialize from './hoc/Initialize';

const App = () => {
  const { pathname } = useLocation();

  const { networkFetching, loadingMessage } = useSelector((state) => state.ui);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loginUsingSavedCredentials());
  }, [dispatch]);

  const { path, loggedInRoutes } = routeDefinitions({ pathname, isLoggedIn });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Loader open={networkFetching} loadMessage={loadingMessage} />
      <Header />
      <SnackBarHOC>
        <HandleError>
          <Initialize>
            <TransitionSwitch>
              {!isLoggedIn && (
                <Route
                  exact
                  path={[
                    '/login',
                    ...loggedInRoutes.map((rte) => `${rte}/login`),
                  ]}
                  component={Login}
                />
              )}
              {!isLoggedIn && <Redirect from="/" to={path} />}
              <Route exact path="/bookings" component={Booking} />
              <Route exact path={ROUTES.PROFILE.path} component={Profile} />
              <Redirect from="/" to={path} />
            </TransitionSwitch>
          </Initialize>
        </HandleError>
      </SnackBarHOC>
    </ThemeProvider>
    // <ThemeProvider theme={theme}>
    //   <Switch>
    //     <Route path="/booking" exact component={Booking} />
    //     <Route path="/profile" component={Profile} />
    //   </Switch>
    // </ThemeProvider>
  );
};

export default App;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#202020',
      contrastText: '#fff',
    },
    secondary: {
      main: '#2272BB',
      contrastText: '#fff',
    },
    error: {
      main: '#f21616',
      contrastText: '#fff',
    },
    success: {
      main: '#09aa1c',
      contrastText: '#fff',
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: ['"Poppins"', 'sans-serif'].join(','),
  },
});
