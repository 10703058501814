import { useEffect, useState } from 'react';

import axios from '../axios';
import useSnackbar from '../hooks/useSnackbar';

const HandleError = ({ children }) => {
  const { showErrorSnackbar } = useSnackbar();

  const [initAxiosSettings, setInitAxiosSettings] = useState(false);

  useEffect(() => {
    const dealWithSuccessResponse = (response) => {
      if (
        (response.status >= 400 && response.status <= 500) ||
        (!!response.data?.statusCode &&
          response.data.statusCode >= 400 &&
          response.data.statusCode <= 511)
      ) {
        showErrorSnackbar(
          response.data?.message ||
            'Oops! Something went wrong. Please try again later...',
        );
      }

      return response;
    };

    const dealWithErrorResponse = (err) => {
      showErrorSnackbar(
        'Oops! Something went wrong. Please try again later...',
      );
      return Promise.reject(err);
    };

    const responseInterceptors = axios.interceptors.response.use(
      dealWithSuccessResponse,
      dealWithErrorResponse,
    );

    setInitAxiosSettings(true);

    return () => {
      axios.interceptors.response.eject(responseInterceptors);
    };
  }, [showErrorSnackbar]);

  return initAxiosSettings ? children : null;
};

export default HandleError;
