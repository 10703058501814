import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

const MyButton = React.forwardRef(
  ({ buttonType, backgroundTransparent, ...props }, ref) => {
    const classes = useStyles();

    let style = props.style;

    if (backgroundTransparent) {
      style = {
        ...style,
        backgroundColor: 'transparent',
      };
    }

    const containedClassesList = [];

    if (buttonType === 'cancel') {
      containedClassesList.push(classes.cancelContained);
    } else if (props.color === 'primary') {
      containedClassesList.push(classes.primaryBtn);
    }

    return (
      <Button
        ref={ref}
        {...props}
        classes={{
          ...props.classes,
          contained:
            buttonType === 'cancel'
              ? [classes.btn, props.className, ...containedClassesList].join(
                  ' ',
                )
              : [classes.btn, props.className].join(' '),
        }}
        style={style}
      />
    );
  },
);

export default MyButton;

const useStyles = makeStyles((theme) => ({
  btn: {
    padding: '10px 10px',
    textTransform: 'capitalize',
    fontSize: 22,
    borderRadius: 10,
    '@media (max-width:780px)': {
      fontSize: 18,
    },
  },
  primaryBtn: {
    '&:hover': {
      background: '#000',
    },
  },
  cancelContained: {
    color: theme.palette.primary.main,
    background: '#c5c5c5',
    '&:hover, &:focus': {
      background: '#000',
      color: '#fff',
    },
  },
}));
