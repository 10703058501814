import React from 'react';
import { fade } from '@material-ui/core';

const ScrollThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    backgroundColor: fade('#000', 0.8),
  };

  return <div style={{ ...style, ...thumbStyle }} {...props} />;
};

export default ScrollThumb;
