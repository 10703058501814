const ROUTES = {
  BOOKING: { path: '/bookings' },
  PROFILE: { path: '/profile' },
  LOGIN: { path: '/login' },
};

const routes = ({ pathname = '', isLoggedIn = false, common = [] }) => {
  let basePathName = '';
  let path = '';

  if (!isLoggedIn) {
    basePathName = ROUTES.LOGIN.path;
  } else {
    basePathName = ROUTES.BOOKING.path;
  }

  const loggedInRoutes = [ROUTES.BOOKING.path, ROUTES.PROFILE.path];
  const loggedOutRoutes = [ROUTES.LOGIN.path];

  if (pathname === '/') {
    path = basePathName;
  } else {
    path = common.includes(pathname)
      ? pathname
      : !isLoggedIn &&
        !pathname.endsWith('/login') &&
        loggedInRoutes.includes(pathname)
      ? `${pathname}/login`
      : isLoggedIn && pathname.endsWith('/login')
      ? pathname.substring(0, pathname.lastIndexOf('/'))
      : basePathName;
  }

  return {
    loggedInRoutes,
    loggedOutRoutes,
    path,
  };
};

export { ROUTES };

export default routes;
